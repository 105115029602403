<script lang="ts">
import {defineComponent} from 'vue'
import {ShoppingBagIcon} from "@heroicons/vue/24/outline";
import type {Product} from "~/types/models";
import {formatPrice} from "../../utils/formatPrice";
import {useUserStore} from "~/stores/user";
import {useCartStore} from "~/stores/cart";

export default defineComponent({
  name: "ProductCard",
  components: {ShoppingBagIcon},
  props: {
    product: {
      type: Object as () => Product,
      required: true
    }
  },
  data() {
    return {
      isHovering: false,
    }
  },
  computed: {
    priceRange() {
      if (this.product.packages && this.product.packages.length > 0) {
        const prices = this.product.packages.map(pkg => pkg.price);
        const minPrice = Math.min(...prices);
        const maxPrice = Math.max(...prices);


        // Check if min and max prices are the same
        if (minPrice === maxPrice) {
          return { singlePrice: minPrice };
        } else {
          return { min: minPrice, max: maxPrice };
        }
      }
      return null;
    }
  },
  methods: {
    formatPrice,
    truncate(str: string, n: number) {
      return (str.length > n) ? str.substr(0, n - 1) + '...' : str;
    },
    addToCart(product: Product) {
      if (!useUserStore().isAuthenticated) {
        navigateTo('/login?redirect_to=' + '/products/' + product.id + '/' + product.slug + '#addToCard')
        return
      }
      if (product.packages?.length > 1) {
        navigateTo('/products/' + product.id + '/' + product.slug)
      } else {
        if (product.packages[0].price === 0) {
          navigateTo('/products/' + product.id + '/' + product.slug)
          return
        }

        const packageId = product.packages[0].id

        $api('/cart', {
          method: 'POST',
          body: {
            package_id: packageId,
          }
        }).then(() => {
          useCartStore().fetchCart()
        })
      }
    },
    goToVendorStore(product: Product) {
      navigateTo('/vendors/' + product.store.id + '/' + product.store.slug)
    },
    getLastCharacter(str: string) {
      const characters = Array.from(str);
      return characters[characters.length - 1];
    }
  }
})
</script>

<template>
  <NuxtLink :href="'/products/' + product.id + '/' + product.slug">
    <div class="max-w-sm rounded overflow-hidden shadow-lg bg-white dark:bg-zinc-800 flex flex-col">
      <div class="m-2"> <!-- Wrapper with margin -->
        <div class="aspect-square rounded-[2px] overflow-hidden"> <!-- Aspect ratio container -->
          <NuxtImg
              format="webp"
              fit="cover"
              height="500"
              width="500"
              loading="lazy"
              v-if="product.thumbnail"
              :src="product.thumbnail.full_url"
              :alt="product.thumbnail.alt_text"
              class="h-full w-full rounded-[2px]"
          />
        </div>
      </div>


      <div class="px-2 py-1 flex flex-col">
        <span class="h-8 sm:h-10 overflow-hidden text-black dark:text-white text-xs sm:text-sm md:text-md line-clamp-2">
          {{ product.name }}
        </span>
      </div>

      <div class="flex flex-row w-full justify-between my-1 mb-2 gap-2 px-2 items-center">
        <!-- Use 'truncate' to apply the text clamping -->
        <div class="text-xs md:text-sm text-red-500 dark:text-red-500 font-light truncate"
             @click.prevent="goToVendorStore(product)">{{ product.store.name }}
        </div>

        <div class="flex flex-row justify-end gap-2 items-center">
          <div v-for="flag in product.flags"
               class="hidden lg:block bg-gray-100 dark:bg-zinc-900 text-black dark:text-white text-xs font-bold text-center p-[6px] py-0.5 rounded truncate">
            {{ flag.name }}
          </div>
          <div v-for="flag in product.flags"
               class="block lg:hidden bg-gray-100 dark:bg-zinc-900 text-black dark:text-white text-xs font-bold text-center p-[6px] py-0.5 rounded truncate">
            {{ getLastCharacter(flag.name) }}
          </div>
        </div>
      </div>


      <div class="w-full flex flex-row justify-center bg-red-500 text-white px-3 py-1 h-8"
           @click.prevent="addToCart(product)" @mouseover="isHovering = true" @mouseleave="isHovering = false">
          <span v-if="!isHovering && priceRange && priceRange.singlePrice !== undefined" class="">
              {{ formatPrice(priceRange.singlePrice) === '$0.00' ? 'Free Download!' : formatPrice(priceRange.singlePrice)  }}
            </span>
           <span v-else-if="!isHovering && priceRange" class="">
              {{ formatPrice(priceRange.min) }} - {{ formatPrice(priceRange.max) }}
            </span>
        <span v-else class="flex items-center justify-center transition-opacity ease-linear duration-500">
          <ShoppingBagIcon class="h-6 w-6 flex-shrink-0" aria-hidden="true"/>
        </span>
      </div>
    </div>
  </NuxtLink>

</template>

<style scoped>

</style>